export const URL = {
  // dev
  // API_BASE_URL:"http://192.168.0.8:3000",

  // creato
  // API_BASE_URL: "http://localhost:3004",
  // API_BASE_URL: "http://192.168.0.19:3004",
  // API_BASE_URL: "http://cricchamp.in:3004",
  API_BASE_URL: "https://api.cricchamp.in",

  adminLogin: "/dhadmin/adminLogin",
  createPlayer: "/apiPublic/createPlayer",
  playerList: "/apiPublic/playerList",
  playerDelete: "/apiPublic/playerDelete",
  playerSreach: "/apiPublic/playerSreach",
  playerEditName: "/apiPublic/playerEditName",
  editplayerImage: "/apiPublic/editplayerImage",
  getUserListAdmin: "/apiPublic/getUserListAdmin",
  searchUser: "/apiPublic/searchUser",
  deleteUserAdmin: "/apiPublic/deleteUserAdmin",
  updateUserAdmin: "/apiPublic/updateUserAdmin",
  getPlayersBysokect: "/apiPublic/getPlayersBysokect",
  getLastWin: "/apiPublic/getLastWin",
  createMatch: "/apiPublic/createMatch",
  getAllMatchAdmin: "/apiPublic/getAllMatchAdmin",
  deletePredication: "/apiPublic/deletePredication",
  deleteMatch: "/apiPublic/deleteMatch",
  topExpertsPointLastSevenDay: "/apiPublic/topExpertsPointLastSevenDay",
  topExpertsPointLastthertyay: "/apiPublic/topExpertsPointLastthertyay",
  getWinnerExpertAdmin: "/apiPublic/getWinnerExpertAdmin",
  getWinnerExpertAdminth: "/apiPublic/getWinnerExpertAdminth",
  cleamnowStatusUpdatesev: "/apiPublic/cleamnowStatusUpdatesev",
  cleamnowStatusUpdateth: "/apiPublic/cleamnowStatusUpdateth",
  cleamnowDeletesev: "/apiPublic/cleamnowDeletesev",
  cleamnowDeleteth: "/apiPublic/cleamnowDeleteth",
  addbetsmen: "/apiPublic/addbetsmen",
  getserBatsman: "/apiPublic/getserBatsman",
  updateserBatsman: "/apiPublic/updateserBatsman",
  deletesBt: "/apiPublic/deletesBt",
  addbollwer: "/apiPublic/addbollwer",
  getserBolwer: "/apiPublic/getserBolwer",
  deletesBw: "/apiPublic/deletesBw",
  createSeriesInfos: "/apiPublic/createSeriesInfos",
  getSeriesInfo: "/apiPublic/getSeriesInfo",
  updateserBolwer: "/apiPublic/updateserBolwer",
  updateserSeriesInfo: "/apiPublic/updateserSeriesInfo",
  deletesSeriesInfo: "/apiPublic/deletesSeriesInfo",
  createPointstables: "/apiPublic/createPointstables",
  getPointstables: "/apiPublic/getPointstables",
  deletesPointstables: "/apiPublic/deletesPointstables",
  updateserPointstables: "/apiPublic/updateserPointstables",
  createTeams: "/apiPublic/createTeams",
  createSeriesTeam: "/apiPublic/createSeriesTeam",
  createSquadbymatchs: "/apiPublic/createSquadbymatchs",
  getTeamList: "/apiPublic/getTeamList",
  updateteams: "/apiPublic/updateteams",
  deletesteams: "/apiPublic/deletesteams",
  getSeriesInfoList: "/apiPublic/getSeriesInfoList",
  getSeriesByTeamList: "/apiPublic/getSeriesByTeamList",
  getTeamListselect: "/apiPublic/getTeamListselect",
  deletesSeriesteams: "/apiPublic/deletesSeriesteams",
  //
  playerListselect: "/apiPublic/playerListselect",
  getSeriesByTeamListselect: "/apiPublic/getSeriesByTeamListselect",
  getSquadbymatchs: "/apiPublic/getSquadbymatchs",
  deletesSquadbymatchs: "/apiPublic/deletesSquadbymatchs",
  updateseriesteams: "/apiPublic/updateseriesteams",
  updateSquadbymatchs: "/apiPublic/updateSquadbymatchs",
  getMatchinfoList: "/apiPublic/getMatchinfoList",
  creatematchInfos: "/apiPublic/creatematchInfos",
  deletesmatchInfod: "/apiPublic/deletesmatchInfod",
  updatesermatchInfos: "/apiPublic/updatesermatchInfos",
  getTeamListforWinerselect: "/apiPublic/getTeamListforWinerselect",
  createCustomer: "/apiPublic/createCustomer",
  getcustomer: "/apiPublic/getcustomer",
  chnagePackageCustomer: "/apiPublic/chnagePackageCustomer",
  renewPackage: "/apiPublic/renewPackage",
  sreachCustomer: "/apiPublic/sreachCustomer",
  createPackages: "/apiPublic/createPackages",
  getPackages: "/apiPublic/getPackages",
  editPackages: "/apiPublic/editPackages",
  createAllapis: "/apiPublic/createAllapis",
  getAllapis: "/apiPublic/getAllapis",
  getAllPackages: "/apiPublic/getAllPackages",
  getapisPackages: "/apiPublic/getapisPackages",
};
