import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../../../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../../Table/DataTable";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ShoetoggelAction } from "../../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../Redux/common/url";

function Player() {
  const navigate = useNavigate();
  const ref = useRef();

  const dispatch = useDispatch();
  const [categoryValue, setcategoryValue] = useState({});
  const [deleteCategory, setdeleteCategory] = useState({});

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);

  const [filterUserview, setfilterUserview] = useState({});

  //   const [comments, setComments] = useState([]);

  const [file, setFile] = useState(null);

  const [photoIndex, setphotoIndex] = useState(0);

  const [isOpen, setisOpen] = useState(false);

  const imageviews = (e) => {
    console.log(comments[0]?.userImage);
    setisOpen(true);
    setphotoIndex(e);
  };

  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };

  // console.log(file);

  //   ShoetoggelAction
  // createPlayer

  const loginAdmin = (e) => {
    // e.preventdefault();
  };

  async function sendMessagesss(e) {
    e.preventDefault();

    if (file !== null) {
      const data = new FormData();

      data.append("name", categoryValue?.name);
      data.append("playerId", categoryValue?.playerId);
      data.append("images", file);
      reset();
      dispatch(ShoetoggelAction.createPlayer(data));

      return () => {};
    } else {
      toast.error("Plase select player image");
      //     console.log("Select img");
    }
  }

  const playerList = useSelector((state) =>
    state?.showtoggel?.player_list ? state?.showtoggel?.player_list : {}
  );

  // console.log(playerList);

  useEffect(() => {
    dispatch(ShoetoggelAction.playerList({ pageNumber: 1 }));

    return () => {};
  }, []);

  const hendledelete = async (e) => {
    // const res = await db.collection("Prediction").doc(deleteCategory).delete();
    // toast.success("deleted ");
    // playerDelete
    dispatch(
      ShoetoggelAction.playerDelete({ id: deleteCategory, pageNumber: 1 })
    );

    return () => {};
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendleCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcategoryValue({ ...categoryValue, [name]: value });
  };

  const comments = playerList?.docs ? playerList?.docs : [];

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    { name: "Image", field: "image", sortable: false },
    { name: "name", field: "name", sortable: true },
    { name: "Player Id", field: "playerId", sortable: true },
    { name: "Date", field: "date", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.playerId.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const [searchData, setsearchData] = useState("");

  const [pagena, setpagena] = useState(1);

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    if (!searchData) {
      setpagena(data);
      dispatch(ShoetoggelAction.playerList({ pageNumber: data }));

      return () => {};
    } else {
      dispatch(
        ShoetoggelAction.playerSreach({ key: searchData, pageNumber: data })
      );

      return () => {};
    }
  };

  const hendleSearch = (e) => {
    // playerSreach
    setsearchData(e);

    dispatch(ShoetoggelAction.playerSreach({ key: e, pageNumber: 1 }));

    return () => {};
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(playerList.total / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);

  const [playerName, setplayerName] = useState("");
  const [playermainId, setplayermainId] = useState("");
  const [playerIDs, setplayerIDs] = useState("");
  const [Filesss, setFilesss] = useState("");

  const editImageHendle = (e) => {
    // setplayerID(e?.id);
    setplayerName(e?.name);
  };

  const handleImages = (event) => {
    setFilesss(event.target.files[0]);
  };

  const editImageHendless = (e) => {
    setplayerIDs(e?.id);
    setplayerName(e?.name);
    setplayermainId(e?.playerId);
  };

  const hendleSubmiteditimg = (e) => {
    const data = new FormData();

    data.append("id", playerIDs);
    data.append("pageNumber", pagena);
    data.append("images", Filesss);
    data.append("name", playerName);
    data.append("playerId", playermainId);

    dispatch(ShoetoggelAction.editplayerImage(data));
    reset();
    return () => {};
  };

  const reset = () => {
    ref.current.value = "";
  };

  const playerEditNamesubmit = (e) => {
    // playerEditName
    dispatch(
      ShoetoggelAction.playerEditName({
        name: playerName,
        pageNumber: pagena,
        // id: playerID,
      })
    );

    return () => {};
  };

  //   editplayerImage

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Player</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Player</h4>

                  <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      data-toggle="modal"
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    {/* <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div> */}
                    <div
                      id="example3_filter"
                      className="dataTables_filter category"
                    >
                      <input
                        placeholder="Search"
                        onChange={(e) => {
                          hendleSearch(e.target.value);
                        }}
                      />
                      {/* <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      /> */}
                    </div>

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData &&
                              commentsData?.map((comment, i) => {
                                const createDate = dateFormat(
                                  comment?.createdAt,
                                  "dd-mm-yyyy"
                                );

                                return (
                                  <tr key={i}>
                                    <td
                                      className="GallaryImg"
                                      onClick={(e) => {
                                        imageviews(i);
                                      }}
                                    >
                                      <img
                                        src={
                                          URL.API_BASE_URL + comment?.userImage
                                        }
                                        alt=""
                                      />
                                    </td>

                                    <td>{comment?.name}</td>
                                    <td>{comment?.playerId}</td>
                                    <td>{createDate}</td>
                                    <td>
                                      <div className="d-flex">
                                        <a
                                          href="#editImage"
                                          data-toggle="modal"
                                          onClick={(e) => {
                                            editImageHendless(comment);
                                          }}
                                          className="btn btn-primary shadow  sharp mr-1"
                                        >
                                          Edit
                                          {/* <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i> */}
                                        </a>
                                        {/* <a
                                          href="#views"
                                          data-toggle="modal"
                                          onClick={(e) => {
                                            editImageHendle(comment);
                                          }}
                                          className="btn btn-primary shadow  sharp mr-1"
                                        >
                                          Edit name
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i> 
                                        </a> */}

                                        <a
                                          href="#delete"
                                          data-toggle="modal"
                                          className="btn btn-danger shadow btn-xs sharp"
                                          onClick={(e) => {
                                            setdeleteCategory(comment?.id);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {comments?.length < itemOffset + itemsPerPage
                        ? comments?.length
                        : itemOffset + itemsPerPage - 1}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {comments?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={URL.API_BASE_URL + comments[photoIndex].userImage}
          nextSrc={comments[(photoIndex + 1) % comments.length]}
          prevSrc={
            comments[(photoIndex + comments.length - 1) % comments.length]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + comments.length - 1) % comments.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % comments.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Prediction
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    <div className="form-group col-md-12">
                      <input
                        type="file"
                        className="form-control input-default "
                        name="image"
                        ref={ref}
                        accept="image/*"
                        onChange={handleImage}
                      />
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        name="name"
                        type="text"
                        value={categoryValue?.name}
                        className="form-control input-default "
                        placeholder="Enter player name"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        name="playerId"
                        type="text"
                        value={categoryValue?.playerId}
                        className="form-control input-default "
                        placeholder="Enter player id"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>
                    {/* <div className="form-group col-md-12">
                     
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        className="form-control"
                        minDate={new Date()}
                        placeholderText="Date & Time"
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                      />
                    </div> */}
                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to delete Prediction
                </h5>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="views" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Update name
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  <input
                    className="form-control"
                    placeholder="Enter name"
                    name="name"
                    value={playerName}
                    onChange={(e) => {
                      setplayerName(e.target.value);
                    }}
                  />
                </div>

                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={playerEditNamesubmit}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="editImage" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Update name
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  <div className="form-group col-md-12">
                    <input
                      type="file"
                      className="form-control input-default "
                      name="image"
                      ref={ref}
                      accept="image/*"
                      onChange={handleImages}
                    />

                    <input
                      className="form-control mt-3"
                      placeholder="Enter name"
                      name="name"
                      value={playerName}
                      onChange={(e) => {
                        setplayerName(e.target.value);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter player id"
                      name="playerID"
                      value={playermainId}
                      onChange={(e) => {
                        setplayermainId(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendleSubmiteditimg}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
