import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { costomeSocket } from "../../../costumSocet/CostomSocket";

function Test1() {
  const id = useParams();
  const [socket, setSocket] = useState(null);
  const [contects, setcontects] = useState([]);

  //   console.log(id);
  //   console.log(contects);

  // useEffect(() => {
  //   if (socket === null) {
  //     // setSocket(io(baseUrl))
  //     setSocket(costomeSocket);
  //   } else {
  //     // get_user_Live

  //     socket.on("liveMatch", (data) => {
  //       // console.log(data);
  //       // console.log(id?.id);

  //       socket.emit("submit_live_match", id?.id);

   
  //     });

  //     //   socket.on("playerList", (data) => {
  //     //     // console.log(data);
  //     //     setcontects(data);
  //     //   });
  //     socket.on("live_match_data", (data) => {
  //       // console.log(data);

  //       if (data?.data !== undefined) {
  //         setcontects(data?.data);
  //       }

  //       // setcontects(data);
  //     });
  //     socket.on("homeList", (data) => {
  //       // console.log(data);
  //       // console.log(data);
  //       // setcontects(data);
  //     });
  //     //   console.log("sdfdsf");
  //     //   socket.emit("user_Details_Get", 1608);

  //     socket.on("test2", (data) => {
  //       // console.log(data);

  //       setcontects(data);
  //     });
  //   }
  // }, [socket]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          {" "}
          <Link to={"/"}>home</Link>
          <p>match</p>
          <p>match_id : {contects?.match_id}</p>
          <p>bolwer : {contects?.bolwer?.name}</p>
          <p> team_a_scores : {contects?.team_a_scores}</p>
          <p>team_a_over : {contects?.team_a_over}</p>
          <p> team_b_scores : {contects?.team_b_scores}</p>
          <p>team_b_over : {contects?.team_b_over}</p>
          <p> ball : {contects?.partnership?.ball}</p>
          <p>match_over : {contects?.match_over}</p>
          <p>match_type : {contects?.match_type}</p>
          <p>team_a : {contects?.team_a}</p>
          <p>team_b : {contects?.team_b}</p>
          <p>toss : {contects?.toss}</p>
          <p>curr_rate : {contects?.curr_rate}</p>
        </div>
        <div className="col-6">
          <p>batsman : </p>

          {contects?.batsman &&
            contects?.batsman?.map((data, i) => {
              return (
                <div key={i}>
                  <p>batsman name : {data?.name}</p>
                  <p>batsman ball : {data?.ball}</p>
                  <p>batsman strike_rate : {data?.strike_rate}</p>
                  <p>batsman run : {data?.run}</p>
                  <p>batsman fours : {data?.fours}</p>
                  <p>batsman sixes : {data?.sixes}</p>
                  <p>batsman out_by : {data?.out_by}</p>
                  <hr />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Test1;
