import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../Component/Dashboard/Dashboard";

import Error404 from "../Component/Layout/Error/Error404";

import Prediction from "../Component/Layout/Flavors/Prediction";

import Test1 from "../Component/Layout/Flavors/Test1";
import Images from "../Component/Layout/Images/Images";
import Index from "../Component/Layout/Index";
import AllApis from "../Component/Layout/Player/AllApis";
import Customber from "../Component/Layout/Player/Customber";
import MatchInfo from "../Component/Layout/Player/MatchInfo";
import Package from "../Component/Layout/Player/Package";
import Player from "../Component/Layout/Player/Player";
import Pointable from "../Component/Layout/Player/Pointable";
import SBatsMan from "../Component/Layout/Player/SBatsMan";
import SBoller from "../Component/Layout/Player/SBoller";
import SeriesInfo from "../Component/Layout/Player/SeriesInfo";
import SeriesTeams from "../Component/Layout/Player/SeriesTeams";
import SquadByMatch from "../Component/Layout/Player/SquadByMatch";
import Teams from "../Component/Layout/Player/Teams";
import WinerSevenDay from "../Component/Layout/Player/WinerSevenDay";
import WinnerThertyDay from "../Component/Layout/Player/WinnerThertyDay";
import Poll from "../Component/Layout/Poll/Poll";
import Login from "../Component/Login/Login";
import AuthAdmin from "./AuthAdmin";

function AppRoutes() {
  return (
    <div>
      {/* <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />

                <ProtectedRoute exact path="/prediction" component={Prediction} />

                <ProtectedRoute exact path="/gallery" component={Images} />


                <LoginProtectRoutes exact path="/login" component={Login} />

                <Route exact path="" component={Error404} />
            </Switch> */}

      <BrowserRouter>
        {/* <Index/> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<AuthAdmin cmp={Dashboard} />} />
          <Route path="/users" element={<AuthAdmin cmp={Prediction} />} />
          <Route path="/test/:id" element={<AuthAdmin cmp={Test1} />} />
          <Route path="/player" element={<AuthAdmin cmp={Player} />} />
          <Route
            path="/winner-seven-day"
            element={<AuthAdmin cmp={WinerSevenDay} />}
          />
          <Route
            path="/winner-thirty-day"
            element={<AuthAdmin cmp={WinnerThertyDay} />}
          />
          <Route path="/home-list" element={<AuthAdmin cmp={Images} />} />
          <Route path="/match" element={<AuthAdmin cmp={Poll} />} />
          <Route
            path="/series-batsman"
            element={<AuthAdmin cmp={SBatsMan} />}
          />

          <Route path="/series-bolwer" element={<AuthAdmin cmp={SBoller} />} />
          <Route path="/series-info" element={<AuthAdmin cmp={SeriesInfo} />} />
          <Route path="/pointtable" element={<AuthAdmin cmp={Pointable} />} />
          <Route path="/teams" element={<AuthAdmin cmp={Teams} />} />
          <Route path="/customber" element={<AuthAdmin cmp={Customber} />} />
          <Route path="/package" element={<AuthAdmin cmp={Package} />} />
          <Route path="/all-apis" element={<AuthAdmin cmp={AllApis} />} />
          <Route path="/match-info" element={<AuthAdmin cmp={MatchInfo} />} />
          <Route
            path="/match-squad"
            element={<AuthAdmin cmp={SquadByMatch} />}
          />
          <Route
            path="/series-teams"
            element={<AuthAdmin cmp={SeriesTeams} />}
          />
          {/* <Route path="/gallery" element={<Images />} /> */}
          <Route path="" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AppRoutes;
