import {
  SHOW_TOGGEL,
  PLAYER_LIST,
  USER_LIST,
  GET_LAST_WIN,
  GET_ALL_MATCH,
  GET_WINNER_EXPERTS,
  GET_WINNER_EXPERTS_TH,
  ADD_BATSMANS,
  ADD_BOLWER,
  GET_SERIES_INFO,
  GET_POINTTABLE,
  CREATE_TEAMS,
  GET_SERIES_INFO_LIST,
  CREATE_SERIES_TEAMS,
  CREATE_TEAMS_LIST_SELECT,
  PLAYER_LIST_SELECT,
  SQUAD_GET,
  CREATE_SERIES_TEAMS_SELECT,
  MATVH_LISTS,
  GET_TEAMS_LIST_FOR_WINNER_SELECT,
  CUSTOMER,
  CREATE_PACKAGE,
  GET_ALL_APIS,
  GET_ALL_PACKAGE,
  GET_APIS_PACKAGE,
} from "../constant";

const initialState = {
  loading: false,
  showToggel: {},
  player_list: {},
  user_list: {},
  get_win_list: {},
  get_winner_experts: {},
  get_winner_experts_th: {},
  get_s_batsman: {},
  get_sweries_info: {},
  get_pointtable: {},
  get_teams: {},
  get_series_teams: {},
  get_squad: {},
  get_package: {},
  customer: {},
  get_all_match: [],
  get_sweries_info_list: [],
  get_teams_select: [],
  player_list_select: [],
  get_teams_select: [],
  get_series_teams_select: [],
  get_teams_list_for_winner_select: [],
  get_All_package: [],
  get_api_package: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOGGEL:
      return { ...state, showToggel: action.payload };

    case PLAYER_LIST:
      return { ...state, player_list: action.payload };
    case USER_LIST:
      return { ...state, user_list: action.payload };
    case GET_LAST_WIN:
      return { ...state, get_win_list: action.payload };
    case GET_ALL_MATCH:
      return { ...state, get_all_match: action.payload };
    case GET_WINNER_EXPERTS:
      return { ...state, get_winner_experts: action.payload };
    case GET_WINNER_EXPERTS_TH:
      return { ...state, get_winner_experts_th: action.payload };
    case ADD_BATSMANS:
      return { ...state, get_s_batsman: action.payload };
    case ADD_BOLWER:
      return { ...state, get_s_bolwer: action.payload };
    case GET_SERIES_INFO:
      return { ...state, get_sweries_info: action.payload };
    case GET_SERIES_INFO_LIST:
      return { ...state, get_sweries_info_list: action.payload };
    case GET_POINTTABLE:
      return { ...state, get_pointtable: action.payload };
    case CREATE_TEAMS:
      return { ...state, get_teams: action.payload };
    case CREATE_SERIES_TEAMS:
      return { ...state, get_series_teams: action.payload };
    case CREATE_TEAMS_LIST_SELECT:
      return { ...state, get_teams_select: action.payload };
    case PLAYER_LIST_SELECT:
      return { ...state, player_list_select: action.payload };
    case CREATE_SERIES_TEAMS_SELECT:
      return { ...state, get_series_teams_select: action.payload };
    case SQUAD_GET:
      return { ...state, get_squad: action.payload };
    case MATVH_LISTS:
      return { ...state, match_info_details: action.payload };
    case GET_TEAMS_LIST_FOR_WINNER_SELECT:
      return { ...state, get_teams_list_for_winner_select: action.payload };
    case CUSTOMER:
      return { ...state, customer: action.payload };
    case CREATE_PACKAGE:
      return { ...state, get_package: action.payload };
    case GET_ALL_PACKAGE:
      return { ...state, get_All_package: action.payload };
    case GET_ALL_APIS:
      return { ...state, get_All_api: action.payload };
    case GET_APIS_PACKAGE:
      return { ...state, get_api_package: action.payload };

    default:
      return state;
  }
};
