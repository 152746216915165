import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../../../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../../Table/DataTable";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ShoetoggelAction } from "../../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../Redux/common/url";

function SquadByMatch() {
  const navigate = useNavigate();
  const ref = useRef();

  const dispatch = useDispatch();
  const [categoryValue, setcategoryValue] = useState({});
  const [deleteCategory, setdeleteCategory] = useState({});

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);

  const [filterUserview, setfilterUserview] = useState({});

  const [pagena, setpagena] = useState(1);
  //   const [comments, setComments] = useState([]);

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);

  const [photoIndex, setphotoIndex] = useState(0);

  const [isOpen, setisOpen] = useState(false);

  const imageviews = (e) => {
    console.log(comments[0]?.userImage);
    setisOpen(true);
    setphotoIndex(e);
  };

  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };
  const handleImage1 = (event) => {
    setFile1(event.target.files[0]);
  };

  // console.log(file);

  //   ShoetoggelAction
  // createPlayer

  const loginAdmin = (e) => {
    // e.preventdefault();
  };

 




  
  async function sendMessagesss(e) {
    e.preventDefault();

    console.log("SDfsdf");

    // // if (file !== null) {
    //   const data = new FormData();

    //   data.append("player_id", categoryValue?.player_id);
    //   //   data.append("series", categoryValue?.series);
    //   data.append("teams", categoryValue?.teams);
    //   data.append("P", categoryValue?.P);
    //   data.append("W", categoryValue?.W);
    //   data.append("L", categoryValue?.L);
    //   data.append("NR", categoryValue?.NR);
    //   data.append("team_id", categoryValue?.team_id);
    //   data.append("play_role", categoryValue?.play_role);
    //   data.append("host_country", categoryValue?.host_country);
    //   data.append("final_wiiner", categoryValue?.final_wiiner);
    //   data.append("qualifirer_team_a", categoryValue?.qualifirer_team_a);
    //   data.append("qualifirer_team_b", categoryValue?.qualifirer_team_b);
    //   data.append("player_of_the_series", categoryValue?.player_of_the_series);
    //   data.append("series_type", categoryValue?.series_type);
    //   data.append("series_play_role", categoryValue?.series_play_role);

    //   data.append("banner", file1);
    //   data.append("images", file);
    // reset();
    dispatch(
      ShoetoggelAction.createSquadbymatchs({
        play_role: categoryValue?.play_role,
        team_id: categoryValue?.team_id,
        player_id: categoryValue?.player_id,
      })
    );

    return () => {};
    // } else {
    //   toast.error("Plase select player image");
    //   //     console.log("Select img");
    // }
  }

  const playerList = useSelector((state) =>
    state?.showtoggel?.get_squad ? state?.showtoggel?.get_squad : {}
  );
  const player_list_select = useSelector((state) =>
    state?.showtoggel?.player_list_select
      ? state?.showtoggel?.player_list_select
      : []
  );

  console.log(player_list_select);
  const get_series_teams_select = useSelector((state) =>
    state?.showtoggel?.get_series_teams_select
      ? state?.showtoggel?.get_series_teams_select
      : []
  );

  console.log(get_series_teams_select);

  useEffect(() => {
    dispatch(ShoetoggelAction.playerListselect({}));
    dispatch(ShoetoggelAction.getSeriesByTeamListselect({}));

    dispatch(ShoetoggelAction.getSquadbymatchs({ pageNumber: 1 }));

    return () => {};
  }, []);

  const hendledelete = async (e) => {
    // const res = await db.collection("Prediction").doc(deleteCategory).delete();
    // toast.success("deleted ");
    // playerDelete
    dispatch(
      ShoetoggelAction.deletesSquadbymatchs({
        id: deleteCategory,
        pageNumber: pagena,
      })
    );

    return () => {};
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendleCategoryAdd = (e) => {
    const { name, value } = e.target;

    console.log(name);
    console.log(value);
    setcategoryValue({ ...categoryValue, [name]: value });
  };

  const comments = playerList?.docs ? playerList?.docs : [];

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    // { name: "Image", field: "image", sortable: false },
    { name: "player ", field: "player_id", sortable: false },
    // { name: "series", field: "series", sortable: false },
    // { name: "teams", field: "teams", sortable: false },
    // { name: "P", field: "P", sortable: false },
    // { name: "W", field: "W", sortable: false },
    // { name: "L", field: "L", sortable: false },
    // { name: "NR", field: "NR", sortable: false },
    { name: "team ", field: "team_id", sortable: false },
    { name: "match type ", field: "type", sortable: false },
    { name: "play_role", field: "play_role", sortable: false },
    // { name: "host_country", field: "host_country", sortable: false },
    // { name: "Team Name", field: "final_wiiner", sortable: false },
    // { name: "Team Short Name", field: "qualifirer_team_a", sortable: false },
    // { name: "Match type", field: "qualifirer_team_b", sortable: false },
    // {
    //   name: "player_of_the_series",
    //   field: "player_of_the_series",
    //   sortable: false,
    // },
    // { name: "series_type", field: "series_type", sortable: false },
    // { name: "series_play_role", field: "series_play_role", sortable: false },
    // { name: "Player Id", field: "playerId", sortable: true },
    // { name: "Date", field: "date", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.playerId.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const [searchData, setsearchData] = useState("");

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    if (!searchData) {
      setpagena(data);
      //   dispatch(ShoetoggelAction.playerList({ pageNumber: data }));
      dispatch(ShoetoggelAction.getSquadbymatchs({ pageNumber: data }));

      return () => {};
    } else {
      //   dispatch(
      //     ShoetoggelAction.playerSreach({ key: searchData, pageNumber: data })
      //   );
      dispatch(ShoetoggelAction.getSquadbymatchs({ pageNumber: data }));

      return () => {};
    }
  };

  const hendleSearch = (e) => {
    // playerSreach
    setsearchData(e);

    dispatch(ShoetoggelAction.playerSreach({ key: e, pageNumber: 1 }));

    return () => {};
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(playerList.total / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);

  const [playerName, setplayerName] = useState({});
  const [playermainId, setplayermainId] = useState("");
  const [playerIDs, setplayerIDs] = useState("");
  const [Filesss, setFilesss] = useState("");

  const editImageHendle = (e) => {
    // setplayerID(e?.id);

    const { name, value } = e.target;
    setplayerName({ ...playerName, [name]: value });
  };

  const handleImages = (event) => {
    setFilesss(event.target.files[0]);
  };

  const editImageHendless = (e) => {
    setplayerIDs(e?.id);
    setplayerName(e);
    setplayermainId(e?.playerId);
  };

  const hendleSubmiteditimg = (e) => {
    const data = new FormData();

    data.append("id", playerName?.id);
    data.append("player_id", playerName?.player_id);
    // data.append("series", playerName?.series);
    data.append("teams", playerName?.teams);
    data.append("P", playerName?.P);
    data.append("W", playerName?.W);
    data.append("L", playerName?.L);
    data.append("NR", playerName?.NR);
    data.append("team_id", playerName?.team_id);
    data.append("play_role", playerName?.play_role);
    // data.append("host_country", playerName?.host_country);
    // data.append("final_wiiner", playerName?.final_wiiner);
    // data.append("qualifirer_team_a", playerName?.qualifirer_team_a);
    // data.append("qualifirer_team_b", playerName?.qualifirer_team_b);
    // data.append("player_of_the_series", playerName?.player_of_the_series);
    // data.append("series_type", playerName?.series_type);
    // data.append("series_play_role", playerName?.series_play_role);

    dispatch(
      ShoetoggelAction.updateSquadbymatchs({
        id: playerName?.id,
        player_id: playerName?.player_id,
        team_id: playerName?.team_id,
        play_role: playerName?.play_role,
        pageNumber: pagena,
      })
    );
    reset();
    return () => {};
  };

  const reset = () => {
    ref.current.value = "";
  };

  const playerEditNamesubmit = (e) => {
    // playerEditName
    dispatch(
      ShoetoggelAction.playerEditName({
        name: playerName,
        pageNumber: pagena,
        // id: playerID,
      })
    );

    return () => {};
  };

  //   editplayerImage

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Point Table</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Point Table</h4>

                  <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      data-toggle="modal"
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    {/* <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div> */}
                    <div
                      id="example3_filter"
                      className="dataTables_filter category"
                    >
                      <input
                        placeholder="Search"
                        onChange={(e) => {
                          hendleSearch(e.target.value);
                        }}
                      />
                      {/* <Search
                        onSearch={(value) => {
                          setSearch(value);
                          setCurrentPage(1);
                        }}
                      /> */}
                    </div>

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center tableRes">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData &&
                              commentsData?.map((comment, i) => {
                                {
                                  /* const createDate = dateFormat(
                                  comment?.createdAt,
                                  "dd-mm-yyyy"
                                ); */
                                }

                                return (
                                  <tr key={i}>
                                    {/* <td
                                      className="GallaryImg"
                                      onClick={(e) => {
                                        imageviews(i);
                                      }}
                                    >
                                      <img
                                        src={URL.API_BASE_URL + comment?.flag}
                                        alt=""
                                      />
                                    </td> */}

                                    <td>{comment?.plyerDetals?.name}</td>
                                    {/* <td>{comment?.series}</td> */}
                                    {/* <td>{comment?.teams}</td>
                                    <td>{comment?.P}</td>
                                    <td>{comment?.W}</td>
                                    <td>{comment?.L}</td>
                                    <td>{comment?.NR}</td> */}
                                    <td>
                                      {comment?.teamDetails?.teamDetals?.name}
                                    </td>
                                    <td>{comment?.teamDetails?.match_type}</td>
                                    <td>{comment?.play_role}</td>
                                    {/* <td>{comment?.host_country}</td>
                                    <td>{comment?.final_wiiner}</td>
                                    <td>{comment?.qualifirer_team_a}</td>
                                    <td>{comment?.qualifirer_team_b}</td>
                                    <td>{comment?.player_of_the_series}</td>
                                    <td>{comment?.series_type}</td>
                                    <td>{comment?.series_play_role}</td> */}

                                    {/* <td>{createDate}</td> */}
                                    <td>
                                      <div className="d-flex">
                                        <a
                                          href="#editImage"
                                          data-toggle="modal"
                                          onClick={(e) => {
                                            editImageHendless(comment);
                                          }}
                                          className="btn btn-primary shadow  sharp mr-1"
                                        >
                                          Edit
                                          {/* <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i> */}
                                        </a>
                                        {/* <a
                                          href="#views"
                                          data-toggle="modal"
                                          onClick={(e) => {
                                            editImageHendle(comment);
                                          }}
                                          className="btn btn-primary shadow  sharp mr-1"
                                        >
                                          Edit name
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i> 
                                        </a> */}

                                        <a
                                          href="#delete"
                                          data-toggle="modal"
                                          className="btn btn-danger shadow btn-xs sharp"
                                          onClick={(e) => {
                                            setdeleteCategory(comment?.id);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {comments?.length < itemOffset + itemsPerPage
                        ? comments?.length
                        : itemOffset + itemsPerPage - 1}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {comments?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={URL.API_BASE_URL + comments[photoIndex].userImage}
          nextSrc={comments[(photoIndex + 1) % comments.length]}
          prevSrc={
            comments[(photoIndex + comments.length - 1) % comments.length]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + comments.length - 1) % comments.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % comments.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Point table
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    <div className="form-group col-md-12">
                      <select
                        className="form-control input-default "
                        name="team_id"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      >
                        <option>select team </option>

                        {get_series_teams_select &&
                          get_series_teams_select?.map((data, i) => {
                            return (
                              <option value={data?.id}>
                                {data?.teamDetals?.name} ({data?.match_type})
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group col-md-12">
                      <select
                        className="form-control input-default "
                        name="player_id"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      >
                        <option>select player </option>

                        {player_list_select &&
                          player_list_select?.map((data, i) => {
                            return (
                              <option value={data?.id}>{data?.name}</option>
                            );
                          })}
                      </select>
                    </div>

                    {/* <div className="form-group col-md-12">
                      <input
                        name="player_id"
                        type="text"
                        value={categoryValue?.player_id}
                        className="form-control input-default "
                        placeholder="Enter  player_id"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        name="team_id"
                        type="text"
                        value={categoryValue?.team_id}
                        className="form-control input-default "
                        placeholder="Enter series team_id"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div> */}
                    <div className="form-group col-md-12">
                      <input
                        name="play_role"
                        type="text"
                        value={categoryValue?.play_role}
                        className="form-control input-default "
                        placeholder="Enter play_role"
                        onChange={(e) => {
                          hendleCategoryAdd(e);
                        }}
                      />
                    </div>

                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Are you sure want to delete Prediction
                </h5>
                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="views" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Update name
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  <input
                    className="form-control"
                    placeholder="Enter name"
                    name="name"
                    value={playerName}
                    onChange={(e) => {
                      setplayerName(e.target.value);
                    }}
                  />
                </div>

                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={playerEditNamesubmit}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="editImage" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Update name
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  <div className="form-group col-md-12">
                    {/* <input
                      type="file"
                      className="form-control input-default "
                      name="image"
                      ref={ref}
                      accept="image/*"
                      onChange={handleImages}
                    /> */}

                    {/* <input
                      className="form-control mt-3"
                      placeholder="Enter player_id"
                      name="player_id"
                      value={playerName?.player_id}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    /> */}
                    {/* <input
                      className="form-control mt-3"
                      placeholder="Enter series"
                      name="series"
                      value={playerName?.series}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    /> */}
                    {/* <input
                      className="form-control mt-3"
                      placeholder="Enter teams"
                      name="teams"
                      value={playerName?.teams}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter P"
                      name="P"
                      value={playerName?.P}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter W"
                      name="W"
                      value={playerName?.W}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter L"
                      name="L"
                      value={playerName?.L}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter NR"
                      name="NR"
                      value={playerName?.NR}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter series team_id"
                      name="team_id"
                      value={playerName?.team_id}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    /> */}
                    <div className="form-group col-md-12">
                      <select
                        className="form-control input-default "
                        name="team_id"
                        onChange={(e) => {
                          editImageHendle(e);
                        }}
                        value={playerName?.team_id}
                      >
                        <option>select team </option>

                        {get_series_teams_select &&
                          get_series_teams_select?.map((data, i) => {
                            return (
                              <option value={data?.id}>
                                {data?.teamDetals?.name} ({data?.match_type})
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group col-md-12">
                      <select
                        className="form-control input-default "
                        name="player_id"
                        onChange={(e) => {
                          editImageHendle(e);
                        }}
                        value={playerName?.player_id}
                      >
                        <option>select player </option>

                        {player_list_select &&
                          player_list_select?.map((data, i) => {
                            return (
                              <option value={data?.id}>{data?.name}</option>
                            );
                          })}
                      </select>
                    </div>
                    <input
                      className="form-control mt-3"
                      placeholder="Enter play_role"
                      name="play_role"
                      value={playerName?.play_role}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    {/* <input
                      className="form-control mt-3"
                      placeholder="Enter host_country"
                      name="host_country"
                      value={playerName?.host_country}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter team name"
                      name="final_wiiner"
                      value={playerName?.final_wiiner}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter team short name"
                      name="qualifirer_team_a"
                      value={playerName?.qualifirer_team_a}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter match type"
                      name="qualifirer_team_b"
                      value={playerName?.qualifirer_team_b}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter player_of_the_series"
                      name="player_of_the_series"
                      value={playerName?.player_of_the_series}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter series_type"
                      name="series_type"
                      value={playerName?.series_type}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    />
                    <input
                      className="form-control mt-3"
                      placeholder="Enter series_play_role"
                      name="series_play_role"
                      value={playerName?.series_play_role}
                      onChange={(e) => {
                        editImageHendle(e);
                      }}
                    /> */}

                    {/* player_of_the_series
    series_type
    series_play_role */}

                    {/* <input
                      className="form-control mt-3"
                      placeholder="Enter player id"
                      name="playerID"
                      value={playermainId}
                      onChange={(e) => {
                        setplayermainId(e.target.value);
                      }}
                    /> */}
                  </div>
                </div>

                <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendleSubmiteditimg}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SquadByMatch;
