export const LOADING = "LOADING";
//user constant
export const SHOW_TOGGEL = "SHOW_TOGGEL";
export const CREATE_PLAYER = "CREATE_PLAYER";
export const PLAYER_LIST = "PLAYER_LIST";
export const USER_LIST = "USER_LIST";
export const GET_LAST_WIN = "GET_LAST_WIN";
export const CREATE_MATCH = "CREATE_MATCH";
export const GET_ALL_MATCH = "GET_ALL_MATCH";
export const TOP_EXPERTS_POINT_LAST_SEVEN = "TOP_EXPERTS_POINT_LAST_SEVEN";
export const GET_WINNER_EXPERTS = "GET_WINNER_EXPERTS";
export const GET_WINNER_EXPERTS_TH = "GET_WINNER_EXPERTS_TH";
export const ADD_BATSMANS = "ADD_BATSMANS";
export const ADD_BOLWER = "ADD_BOLWER";
export const GET_SERIES_INFO = "GET_SERIES_INFO";
export const GET_SERIES_INFO_LIST = "GET_SERIES_INFO_LIST";
export const GET_POINTTABLE = "GET_POINTTABLE";
export const CREATE_TEAMS = "CREATE_TEAMS";
export const CREATE_TEAMS_LIST_SELECT = "CREATE_TEAMS_LIST_SELECT";
export const CREATE_SERIES_TEAMS = "CREATE_SERIES_TEAMS";
export const CREATE_SERIES_TEAMS_SELECT = "CREATE_SERIES_TEAMS_SELECT";
export const PLAYER_LIST_SELECT = "PLAYER_LIST_SELECT";
export const SQUAD_GET = "SQUAD_GET";
export const MATVH_LISTS = "MATVH_LISTS";
export const GET_TEAMS_LIST_FOR_WINNER_SELECT =
  "GET_TEAMS_LIST_FOR_WINNER_SELECT";
export const CUSTOMER = "CUSTOMER";
export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const GET_ALL_PACKAGE = "GET_ALL_PACKAGE";
export const GET_ALL_APIS = "GET_ALL_APIS";
export const GET_APIS_PACKAGE = "GET_APIS_PACKAGE";
