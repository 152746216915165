import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../../../Firebase";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { TableHeader, Pagination, Search } from "../../Table/DataTable";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { ShoetoggelAction } from "../../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function Images() {
  const ref = useRef();

  const dispatch = useDispatch();

  const [categoryValue, setcategoryValue] = useState({});
  const [deleteCategory, setdeleteCategory] = useState({});

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);

  const [filterUserview, setfilterUserview] = useState({});
  const [protectProfile, setprotectProfile] = useState(false);

  const [comments, setComments] = useState([]);

  const [file, setFile] = useState(null);
  const [homelistData, sethomelistData] = useState(null);

  console.log(comments);

  const [photoIndex, setphotoIndex] = useState(0);

  const [isOpen, setisOpen] = useState(false);

  const imageviews = (e) => {
    setisOpen(true);
    setphotoIndex(e);
  };

  const handleImage = (event) => {
    setFile(event.target.files[0]);
  };

  const playerList = useSelector((state) =>
    state?.showtoggel?.player_list ? state?.showtoggel?.player_list : {}
  );
  const get_win_list = useSelector((state) =>
    state?.showtoggel?.get_win_list ? state?.showtoggel?.get_win_list : {}
  );

  console.log(get_win_list);

  useEffect(() => {
    dispatch(ShoetoggelAction.playerList({ pageNumber: 1 }));

    return () => {};
  }, []);

  async function sendMessagesss(e) {
    e.preventDefault();

    if (file !== null) {
      const ref = storage.ref(`/slider/gallery/${file.name}`);
      const uploadTask = ref.put(file);
      const oderDone = uploadTask.on(
        "state_changed",
        console.log,
        console.error,
        () => {
          ref.getDownloadURL().then((url) => {
            setFile(null);

            const abc = db.collection("slider").add({
              image: url,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            toast.success("Image successfully Add");
            reset();
          });
        }
      );
    } else {
    }
  }

  const hendleWinTime = (e) => {
    console.log(e);

    dispatch(ShoetoggelAction.getLastWin({ seriesName: e }));

    return () => {};
  };

  const reset = () => {
    ref.current.value = "";
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: "http://apicricketchampion.in/webservices/seriesList/520b53dbf8af7fc7d5a98dd0804654we",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setComments(response?.data?.data);
        //   console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const hendleProtectProfile = (e) => {
    setprotectProfile(e);

    db.collection("sliderActive").doc("YhK4d1g9RfYJ5aZFw4Ik").update({
      sliderActive: e,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // const abc =   db.collection("sliderActive").add({
    //     sliderActive: e,
    //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    // });
  };

  useEffect(() => {
    // db.collection("slider")
    //   .orderBy("createdAt", "desc")
    //   .onSnapshot((snapshot) => {
    //     const oderdetails = snapshot.docs.map((e) => {
    //       const data = e.data();
    //       data.id = e.id;
    //       return data;
    //     });
    //     // setComments(oderdetails);
    //   });
    // db.collection("sliderActive")
    //   .doc("YhK4d1g9RfYJ5aZFw4Ik")
    //   .onSnapshot((snapshot) => {
    //     setprotectProfile(snapshot?.data()?.sliderActive);
    //   });
  }, []);

  const hendledelete = async (e) => {
    const res = await db.collection("slider").doc(deleteCategory).delete();
    toast.success("deleted ");
  };

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  const hendleCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcategoryValue({ ...categoryValue, [name]: value });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    { name: "Image", field: "slider", sortable: false },
    // { name: "Number", field: "number", sortable: false },
    { name: "series", field: "series", sortable: false },
    { name: "series_date", field: "series_date", sortable: false },
    // { name: "Add Date", field: "createDate", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //     computedComments = computedComments.filter(
    //         comment =>
    //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //             comment.email.toLowerCase().includes(search.toLowerCase())
    //     );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected % (comments.length / 2);
    setCurrentPage(newOffset + 1);
    setItemOffset(newOffset * itemsPerPage);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(comments.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Home List</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Home List</h4>

                  {/* <div className="addPlus">
                    <a
                      href="#category"
                      className="login_button newQue"
                      data-toggle="modal"
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </a>
                  </div> */}
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div>
                    {/* <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div> */}

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData.map((comment, i) => {
                              const createDate = dateFormat(
                                comment?.createdAt?.toDate(),
                                "dd-mm-yyyy"
                              );

                              return (
                                <tr key={i}>
                                  <td
                                    className="GallaryImg"
                                    onClick={(e) => {
                                      imageviews(i);
                                    }}
                                  >
                                    <img src={comment?.image} alt="" />
                                  </td>

                                  <td>{comment?.series}</td>
                                  <td>{comment?.series_date}</td>
                                  {/* <td>{createDate}</td> */}
                                  <td>
                                    <div className="d-flex">
                                      {/* <a
                                                                                href="#views" data-toggle="modal" onClick={(e) => { viewHendle(comment?.id) }}
                                                                                className="btn btn-primary shadow btn-xs sharp mr-1"
                                                                            >
                                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a> */}

                                      <a
                                        href="#delete"
                                        data-toggle="modal"
                                        className="btn btn-success shadow btn-xs sharp"
                                        onClick={(e) => {
                                          hendleWinTime(comment?.series);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {comments?.length < itemOffset + itemsPerPage
                        ? comments?.length
                        : itemOffset + itemsPerPage}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {comments?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={comments[photoIndex].image}
          nextSrc={comments[(photoIndex + 1) % comments.length]}
          prevSrc={
            comments[(photoIndex + comments.length - 1) % comments.length]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + comments.length - 1) % comments.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % comments.length)
          }
        />
      )}

      <div className="bs-example">
        <div id="category" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  Create Category
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body" style={{ padding: "26px" }}>
                <div className="assignment_form loader-parent">
                  <form>
                    {/* <input
                                            name="name"
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Enter category name"
                                            onChange={(e) => {
                                                hendleCategoryAdd(e);
                                            }}
                                        /> */}

                    <input
                      type="file"
                      className="form-control input-default "
                      name="image"
                      ref={ref}
                      accept="image/*"
                      onChange={handleImage}
                    />

                    <div className="submitBtn">
                      <button
                        type="button"
                        onClick={(e) => {
                          sendMessagesss(e);
                        }}
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  <div
                    className="loader-bx lodding "
                    style={{ display: "none" }}
                  >
                    <img src="img/lodder.gif" alt="loader" className="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bs-example">
        <div id="delete" className="modal fade" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div>
                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                  {/* Are you sure want to delete Image */}
                  {get_win_list?.seriesName}
                </h5>

                <div style={{ padding: "8px 27px" }}>
                  {get_win_list?.teamWinLoss &&
                    get_win_list?.teamWinLoss?.map((data, i) => {
                      return (
                        <div className="row">
                          <div className="col-6">
                            <p className="matchicon">
                              {" "}
                              <img src={data?.image} alt="" /> {data?.teamName}{" "}
                            </p>
                          </div>
                          <div className="col-6 WInLossResult">
                            {data?.result &&
                              data?.result?.map((datas, index) => {
                                return <div className={datas}></div>;
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* <div className="deleteBtn">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-dismiss="modal"
                    onClick={hendledelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Images;
