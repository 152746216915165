import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { URL } from "./url";
let Api = axios.create({
  baseURL: URL.API_BASE_URL,
  headers: localStorage.getItem("token")
    ? { Authorization: `Bearer ${localStorage.getItem("token")}` }
    : undefined,
});
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error?.response?.status);
    // Do something with response error
    return Promise.reject(error);
  }
);
export default Api;
