import React, { useEffect, useState } from "react";
import { auth, firebase } from "../../Firebase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();

  const [adminLoginValue, setadminLoginValue] = useState({});

  // Inputs
  const [mynumber, setnumber] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");

  useEffect(() => {
    if (localStorage.getItem("phone_Number")) {
      navigate("/");
    }
  }, []);

  const login = (e) => {
    setshow(true);
  };

  const otpV = (e) => {
    if (otp == "118899") {
      toast.success("Login successfully");
      localStorage.setItem("phone_Number", mynumber);
      navigate("/");
    } else {
      toast.error("invalid otp");
    }
  };

  // Sent OTP
  const signin = (e) => {
    // e.preventdefault()

    // if (mynumber == "919610641351" || mynumber == "919314267002" || mynumber == "918005581767") {

    if (mynumber === "" || mynumber.length < 10) return;

    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");

    auth
      .signInWithPhoneNumber("+" + mynumber, verify)
      .then((result) => {
        setfinal(result);

        toast.success("otp sent your number");

        setshow(true);
      })
      .catch((err) => {
        toast.error(err);

        window.location.reload();
      });

    // } else {
    //   toast.error("Invalid number");
    // }
  };

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        toast.success("Login successfully");
        localStorage.setItem("phone_Number", result?.user?.phoneNumber);
        navigate("/");
      })
      .catch((err) => {
        toast.error("Wrong Otp");
        // alert("Wrong code");
      });
  };

  return (
    <div>
      <div className="authincation h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3 loginPageLogo">
                        {/* <a ><img src="images/logo1.png" alt="" /></a> */}
                      </div>
                      <h4 className="text-center mb-4 text-white">
                        Sign in your account
                      </h4>
                      <div className="loginUser">
                        {/* <h1 className="heading">
              {" "}
              <span>Login</span>
       
            </h1> */}

                        <div className="loginBox">
                          <div style={{ display: !show ? "block" : "none" }}>
                            <PhoneInput
                              country={"in"}
                              value={mynumber}
                              onChange={setnumber}
                            />

                            <div id="recaptcha-container"></div>
                            <div className="text-center">
                              <button
                                onClick={(e) => {
                                  login(e);
                                }}
                                className="btn bg-white text-primary btn-block logInBtn"
                              >
                                Sign Me In
                              </button>
                            </div>

                            <br />
                            <br />
                          </div>
                          <div style={{ display: show ? "block" : "none" }}>
                            <input
                              type="text"
                              placeholder={"Enter your OTP"}
                              className="form-control"
                              onChange={(e) => {
                                setotp(e.target.value);
                              }}
                            ></input>
                            <br />
                            <br />
                            <div id="recaptcha-container"></div>
                            <div className="text-center">
                              <button
                                onClick={(e) => {
                                  otpV(e);
                                }}
                                className="btn bg-white text-primary btn-block logInBtn"
                              >
                                Verify
                              </button>
                            </div>

                            {/* <div className="text-center">
                              <button onClick={(e) => {
                                signin(e);
                              }} className="btn bg-white text-primary btn-block logInBtn">Sign Me In</button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="new-account mt-3">
                        {/* <p className="text-white">Don't have an account? <a className="text-white" href="page-register.html">Sign up</a></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
