import firebase from 'firebase';
  
const firebaseConfig = {
  // apiKey: "AIzaSyDk8bZbCySRuItJPy1rsnoba046zDnXeVk",
  // authDomain: "starlive-5b933.firebaseapp.com",
  // databaseURL: "https://starlive-5b933-default-rtdb.firebaseio.com",
  // projectId: "starlive-5b933",
  // storageBucket: "starlive-5b933.appspot.com",
  // messagingSenderId: "244314907459",
  // appId: "1:244314907459:web:c50ad6f8eb68421e6845f6",
  // measurementId: "G-SNXG0MDKMP"


  apiKey: "AIzaSyBfc3JvCT3jULPheEZuYyDPPmQNDs_nGK4",
  authDomain: "kabir-live-line.firebaseapp.com",
  databaseURL: "https://kabir-live-line-default-rtdb.firebaseio.com",
  projectId: "kabir-live-line",
  storageBucket: "kabir-live-line.appspot.com",
  messagingSenderId: "390266793659",
  appId: "1:390266793659:web:7e68ab01e92f6aa997c39e",
  measurementId: "G-9J96HRZWXC"






};
  
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
const db = firebase.firestore()
const storage = firebase.storage()
export {auth , firebase, db,storage};