import { toast } from "react-toastify";
import Api from "../api";
import {
  SHOW_TOGGEL,
  CREATE_PLAYER,
  LOADING,
  PLAYER_LIST,
  USER_LIST,
  GET_LAST_WIN,
  CREATE_MATCH,
  GET_ALL_MATCH,
  TOP_EXPERTS_POINT_LAST_SEVEN,
  GET_WINNER_EXPERTS,
  GET_WINNER_EXPERTS_TH,
  ADD_BATSMANS,
  ADD_BOLWER,
  GET_SERIES_INFO,
  GET_POINTTABLE,
  CREATE_TEAMS,
  GET_SERIES_INFO_LIST,
  CREATE_SERIES_TEAMS,
  CREATE_TEAMS_LIST_SELECT,
  PLAYER_LIST_SELECT,
  SQUAD_GET,
  CREATE_SERIES_TEAMS_SELECT,
  MATVH_LISTS,
  GET_TEAMS_LIST_FOR_WINNER_SELECT,
  CUSTOMER,
  CREATE_PACKAGE,
  GET_ALL_APIS,
  GET_ALL_PACKAGE,
  GET_APIS_PACKAGE,
} from "../constant";
import { URL } from "../url";

const loading = (data) => {
  return { type: LOADING, payload: data };
};

const getUsersDetailsAction = (data) => {
  return { type: SHOW_TOGGEL, payload: data };
};
const createPlayerAction = (data) => {
  return { type: CREATE_PLAYER, payload: data };
};
const playerListAction = (data) => {
  return { type: PLAYER_LIST, payload: data };
};
const userListAction = (data) => {
  return { type: USER_LIST, payload: data };
};
const getLastListAction = (data) => {
  return { type: GET_LAST_WIN, payload: data };
};
const createMatchAction = (data) => {
  return { type: CREATE_MATCH, payload: data };
};
const getAllMatchAction = (data) => {
  return { type: GET_ALL_MATCH, payload: data };
};
const topExpertsPointLastSevenDayAction = (data) => {
  return { type: TOP_EXPERTS_POINT_LAST_SEVEN, payload: data };
};
const getWinnerExpertAdminAction = (data) => {
  return { type: GET_WINNER_EXPERTS, payload: data };
};
const getWinnerExpertAdminthAction = (data) => {
  return { type: GET_WINNER_EXPERTS_TH, payload: data };
};
const addBatsmanAction = (data) => {
  return { type: ADD_BATSMANS, payload: data };
};
const addBolwerAction = (data) => {
  return { type: ADD_BOLWER, payload: data };
};
const getSeriesInfoAction = (data) => {
  return { type: GET_SERIES_INFO, payload: data };
};
const getSeriesInfoListAction = (data) => {
  return { type: GET_SERIES_INFO_LIST, payload: data };
};
const createPointstablesAction = (data) => {
  return { type: GET_POINTTABLE, payload: data };
};
const createteamsAction = (data) => {
  return { type: CREATE_TEAMS, payload: data };
};
const getSeriesTeamListAction = (data) => {
  return { type: CREATE_SERIES_TEAMS, payload: data };
};
const getTeamListselectAction = (data) => {
  return { type: CREATE_TEAMS_LIST_SELECT, payload: data };
};
const playerListSelectAction = (data) => {
  return { type: PLAYER_LIST_SELECT, payload: data };
};
const getSeriesTeamListSelectAction = (data) => {
  return { type: CREATE_SERIES_TEAMS_SELECT, payload: data };
};
const getsquadAction = (data) => {
  return { type: SQUAD_GET, payload: data };
};
const getmatchinfoListAction = (data) => {
  return { type: MATVH_LISTS, payload: data };
};
const getTeamListforWinerselectAction = (data) => {
  return { type: GET_TEAMS_LIST_FOR_WINNER_SELECT, payload: data };
};
const getcustomerAction = (data) => {
  return { type: CUSTOMER, payload: data };
};
const getpackageAction = (data) => {
  return { type: CREATE_PACKAGE, payload: data };
};
const getallApisAction = (data) => {
  return { type: GET_ALL_APIS, payload: data };
};
const getallpackageAction = (data) => {
  return { type: GET_ALL_PACKAGE, payload: data };
};
const getapipackageAction = (data) => {
  return { type: GET_APIS_PACKAGE, payload: data };
};

export const ShowtoggelAction = (data) => {
  return async (dispatch) => {
    dispatch(getUsersDetailsAction(data));
  };
};

export const getapisPackages = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getapisPackages}`, data);

      console.log(response?.data);
      dispatch(getapipackageAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createCustomer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createCustomer}`, data);

      console.log(response?.data);
      dispatch(getcustomerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getcustomer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getcustomer}`, data);

      console.log(response?.data);
      dispatch(getcustomerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const chnagePackageCustomer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.chnagePackageCustomer}`, data);

      console.log(response?.data);
      dispatch(getcustomerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const renewPackage = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.renewPackage}`, data);

      console.log(response?.data);
      dispatch(getcustomerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createPackages = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createPackages}`, data);

      console.log(response?.data);
      dispatch(getpackageAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createAllapis = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createAllapis}`, data);

      // console.log(response?.data);
      dispatch(getallApisAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAllapis = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getAllapis}`, data);

      console.log(response?.data);
      dispatch(getallApisAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const editPackages = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.editPackages}`, data);

      console.log(response?.data);
      dispatch(getpackageAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAllPackages = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getAllPackages}`, data);

      console.log(response?.data);
      dispatch(getallpackageAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getPackages = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getPackages}`, data);

      console.log(response?.data);
      dispatch(getpackageAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sreachCustomer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.sreachCustomer}`, data);

      console.log(response?.data);
      dispatch(getcustomerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addbetsmen = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.addbetsmen}`, data);
      dispatch(addBatsmanAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateserBatsman = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateserBatsman}`, data);
      dispatch(addBatsmanAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesBt = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesBt}`, data);
      dispatch(addBatsmanAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getserBatsman = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getserBatsman}`, data);
      dispatch(addBatsmanAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addbollwer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.addbollwer}`, data);
      dispatch(addBolwerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSeriesInfoList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getSeriesInfoList}`, data);
      dispatch(getSeriesInfoListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getTeamListselect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getTeamListselect}`, data);
      dispatch(getTeamListselectAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getTeamListforWinerselect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getTeamListforWinerselect}`, data);
      dispatch(getTeamListforWinerselectAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const playerListselect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerListselect}`, data);
      dispatch(playerListSelectAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSeriesByTeamListselect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getSeriesByTeamListselect}`, data);
      dispatch(getSeriesTeamListSelectAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateSquadbymatchs = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateSquadbymatchs}`, data);
      dispatch(getsquadAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSquadbymatchs = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createSquadbymatchs}`, data);
      dispatch(getsquadAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesSquadbymatchs = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesSquadbymatchs}`, data);
      dispatch(getsquadAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSquadbymatchs = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getSquadbymatchs}`, data);
      dispatch(getsquadAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateseriesteams = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateseriesteams}`, data);
      dispatch(getSeriesTeamListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSeriesTeam = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createSeriesTeam}`, data);
      dispatch(getSeriesTeamListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesSeriesteams = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesSeriesteams}`, data);
      dispatch(getSeriesTeamListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSeriesByTeamList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getSeriesByTeamList}`, data);
      dispatch(getSeriesTeamListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getMatchinfoList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getMatchinfoList}`, data);
      dispatch(getmatchinfoListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createTeams = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createTeams}`, data);
      dispatch(createteamsAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getTeamList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getTeamList}`, data);
      dispatch(createteamsAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateteams = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateteams}`, data);
      dispatch(createteamsAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesteams = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesteams}`, data);
      dispatch(createteamsAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createPointstables = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createPointstables}`, data);
      dispatch(createPointstablesAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getPointstables = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getPointstables}`, data);
      dispatch(createPointstablesAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesPointstables = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesPointstables}`, data);
      dispatch(createPointstablesAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateserPointstables = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateserPointstables}`, data);
      dispatch(createPointstablesAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createSeriesInfos = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createSeriesInfos}`, data);
      dispatch(getSeriesInfoAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesermatchInfos = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updatesermatchInfos}`, data);
      dispatch(getmatchinfoListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const creatematchInfos = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.creatematchInfos}`, data);
      dispatch(getmatchinfoListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesmatchInfod = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesmatchInfod}`, data);
      dispatch(getmatchinfoListAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletesSeriesInfo = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesSeriesInfo}`, data);
      dispatch(getSeriesInfoAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateserSeriesInfo = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateserSeriesInfo}`, data);
      dispatch(getSeriesInfoAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSeriesInfo = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getSeriesInfo}`, data);
      dispatch(getSeriesInfoAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getserBolwer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getserBolwer}`, data);
      dispatch(addBolwerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateserBolwer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateserBolwer}`, data);
      dispatch(addBolwerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesBw = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deletesBw}`, data);
      dispatch(addBolwerAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllMatchAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getAllMatchAdmin}`, data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteMatch = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deleteMatch}`, data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const topExpertsPointLastSevenDay = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(
        `${URL.topExpertsPointLastSevenDay}`,
        data
      );
      dispatch(topExpertsPointLastSevenDayAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const topExpertsPointLastthertyay = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(
        `${URL.topExpertsPointLastthertyay}`,
        data
      );
      // dispatch(topExpertsPointLastSevenDayAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const cleamnowStatusUpdatesev = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.cleamnowStatusUpdatesev}`, data);
      dispatch(getWinnerExpertAdminAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const cleamnowDeletesev = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.cleamnowDeletesev}`, data);
      dispatch(getWinnerExpertAdminAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getWinnerExpertAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getWinnerExpertAdmin}`, data);
      dispatch(getWinnerExpertAdminAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getWinnerExpertAdminth = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getWinnerExpertAdminth}`, data);
      dispatch(getWinnerExpertAdminthAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const cleamnowStatusUpdateth = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.cleamnowStatusUpdateth}`, data);
      dispatch(getWinnerExpertAdminthAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const cleamnowDeleteth = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.cleamnowDeleteth}`, data);
      dispatch(getWinnerExpertAdminthAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      // toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createMatch = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createMatch}`, data);
      console.log(response?.data?.data);
      dispatch(getAllMatchAction(response?.data?.data));
      // dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createPlayer = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.createPlayer}`, data);
      dispatch(createPlayerAction(response?.data?.data));
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const playerList = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerList}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getLastWin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getLastWin}`, data);
      dispatch(getLastListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPlayersBysokect = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getPlayersBysokect}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const playerDelete = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerDelete}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const playerSreach = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerSreach}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const playerEditName = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.playerEditName}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const editplayerImage = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.editplayerImage}`, data);
      dispatch(playerListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserListAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.getUserListAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const searchUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.searchUser}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteUserAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.deleteUserAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateUserAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token_admin");
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const response = await Api.post(`${URL.updateUserAdmin}`, data);
      dispatch(userListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
